import { ApolloLink } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";

import { getSessionTokenCookieName } from "@/src/app/_lib/auth/get-session-token-cookie-name";
import { sudoHeadersByKey } from "@/src/app/api/graphql/types";

export const getAuthLink = (
  sessionTokenValue: string | undefined,
  fnr: string | undefined,
): ApolloLink | undefined =>
  sessionTokenValue
    ? setContext(() => {
        const headers: Record<string, string> = {
          Cookie: `${getSessionTokenCookieName()}=${sessionTokenValue}`,
        };

        if (fnr) {
          headers[sudoHeadersByKey.Fnr] = fnr;
        }

        // TODO: Add dynamic orgnr, when support exists in API
        // headers[sudoHeadersByKey.Orgnr] = "971035854";

        return { headers };
      })
    : undefined;
