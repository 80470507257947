import { Pathnames } from "next-intl/navigation";

export const locales = ["en", "nn", "nb"] as const;

export const DEFAULT_LOCALE = "nb";

export type Locale = (typeof locales)[number];

export type LocaleFullName = Record<Locale, string>;

export const LocaleToFullName = {
  en: "English",
  nn: "Norsk - Nynorsk",
  nb: "Norsk - Bokmål",
} satisfies LocaleFullName;

export function getAvailableLocalesToFullName(
  unusedOnly: true,
  currentLocale: string,
): Partial<Record<Locale, string>>;
export function getAvailableLocalesToFullName(): LocaleFullName;

export function getAvailableLocalesToFullName(
  unusedOnly = false,
  currentLocale = DEFAULT_LOCALE,
) {
  if (unusedOnly) {
    return Object.entries(LocaleToFullName).reduce(
      (acc: Partial<LocaleFullName>, [locale, name]) =>
        locale === currentLocale ? acc : { ...acc, [locale]: name },
      {},
    );
  }

  return LocaleToFullName;
}

export type Pathname = keyof typeof pathnames;

export const pathnames = {
  "/": "/",
  "/admission": {
    nb: "/opptak",
    nn: "/opptak",
    en: "/admission",
  },
  "/admission/[opptakId]/application/confirmation": {
    nb: "/opptak/[opptakId]/soknad/bekreftelse",
    nn: "/opptak/[opptakId]/soknad/bekreftelse",
    en: "/admission/[opptakId]/application/confirmation",
  },
  "/admission/[opptakId]/application/documentation": {
    nb: "/opptak/[opptakId]/soknad/dokumentasjon",
    nn: "/opptak/[opptakId]/soknad/dokumentasjon",
    en: "/admission/[opptakId]/application/documentation",
  },
  "/admission/[opptakId]/find-studies": {
    nb: "/opptak/[opptakId]/finn-studier",
    nn: "/opptak/[opptakId]/finn-studiar",
    en: "/admission/[opptakId]/find-studies",
  },
  "/admission/[opptakId]/find-studies/[studieId]": {
    nb: "/opptak/[opptakId]/finn-studier/[studieId]",
    nn: "/opptak/[opptakId]/finn-studiar/[studieId]",
    en: "/admission/[opptakId]/find-studies/[studieId]",
  },
  "/admission/[opptakId]/application/prioritize": {
    nb: "/opptak/[opptakId]/soknad/prioriter",
    nn: "/opptak/[opptakId]/soknad/prioriter",
    en: "/admission/[opptakId]/application/prioritize",
  },
  "/admission/[opptakId]/application/summary": {
    nb: "/opptak/[opptakId]/soknad/oppsummering",
    nn: "/opptak/[opptakId]/soknad/oppsumering",
    en: "/admission/[opptakId]/application/summary",
  },
  "/admission/[opptakId]/application/response": {
    nb: "/opptak/[opptakId]/soknad/svarskjema",
    nn: "/opptak/[opptakId]/soknad/svarskjema",
    en: "/admission/[opptakId]/application/response-form",
  },
  "/soker": {
    nb: "/soker",
    nn: "/soker",
    en: "/applier",
  },
  "/settings/create-fake-user": {
    nb: "/instillinger/opprett-testsoker",
    nn: "/instillinger/opprett-testsoker",
    en: "/settings/create-fake-user",
  },
  "/work-in-progress": {
    nb: "/under-arbeid",
    nn: "/under-arbeid",
    en: "/work-in-progress",
  },
  "/mine-soknader": {
    nb: "/mine-soknader",
    nn: "/mine-soknader",
    en: "/my-applications",
  },
  "/mine-soknader/[id]": {
    nb: "/mine-soknader/[id]",
    nn: "/mine-soknader/[id]",
    en: "/my-applications/[id]",
  },
  "/min-oversikt": {
    nb: "/min-oversikt",
    nn: "/min-oversikt",
    en: "/dashboard",
  },
  "/login": {
    nb: "/logg-inn",
    nn: "/logg-inn",
    en: "/login",
  },
  "/logged-out": {
    nb: "/logget-ut",
    nn: "/logget-ut",
    en: "/logged-out",
  },
  "/profile": {
    nb: "/profil",
    nn: "/profil",
    en: "/profile",
  },
} satisfies Pathnames<typeof locales>;
