import type { ParsedUrlQueryInput } from "node:querystring";

// Helps with handling search params and the transition from URLSearchParams to an object that next-intl likes
export type SearchParamValue = ParsedUrlQueryInput[keyof ParsedUrlQueryInput];
export type SearchParamValues = Record<string, SearchParamValue>;

// Converts URLSearchParams to a format that next-intl likes
export const urlSearchParamsToSearchParams = (
  searchParams: URLSearchParams,
): SearchParamValues =>
  [...searchParams.entries()].reduce((acc: SearchParamValues, [key, value]) => {
    const prevValue = Object.prototype.hasOwnProperty.call(acc, key)
      ? acc[key]
      : undefined;

    if (prevValue) {
      if (Array.isArray(prevValue)) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        acc[key] = [...prevValue, value];
      } else {
        acc[key] = [prevValue, value] as SearchParamValue;
      }

      return acc;
    }

    acc[key] = value;

    return acc;
  }, {});
