import { createLocalizedPathnamesNavigation } from "next-intl/navigation";

import { Pathname, locales, pathnames } from "./locale";

// Native exports from next-intl
export const { Link, redirect, usePathname, useRouter, getPathname } =
  createLocalizedPathnamesNavigation({ locales, pathnames });

export const getLocalizedPathnames = (pathname: Pathname): string[] =>
  Object.values(pathnames[pathname]);
