"use client";

import { useLocale, useTranslations } from "next-intl";
import { useParams, useSearchParams } from "next/navigation";

import { ButtonProps } from "@sikt/sds-button";
import { SignInIcon } from "@sikt/sds-icons";

import ButtonLink from "@/src/components/button-link/button-link";
import { Locale } from "@/src/intl/locale";
import { getPathname, usePathname } from "@/src/intl/navigation";
import { urlSearchParamsToSearchParams } from "@/src/intl/search-params";

interface LoginButtonLinkProps {
  variant: ButtonProps["variant"];
  className?: string;
}

const LogInButtonLink = ({
  variant,
  className,
  ...props
}: LoginButtonLinkProps) => {
  const t = useTranslations("header");

  const locale = useLocale() as Locale;
  const pathnameWithoutParams = usePathname();
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion
  const params = useParams() as never;

  const pathnameWithParams = getPathname({
    href: {
      pathname: pathnameWithoutParams,
      params,
      query: urlSearchParamsToSearchParams(useSearchParams()),
    },
    locale,
  });

  const query = { redirectTo: pathnameWithParams };

  return (
    <ButtonLink
      {...props}
      className={className}
      href={{
        pathname: "/login",
        query,
      }}
      icon={<SignInIcon />}
      variant={variant}
    >
      {t("login")}
    </ButtonLink>
  );
};

export default LogInButtonLink;
